import { Grid, Input, Table } from "semantic-ui-react";
import "./packagingTable.scss";
import Icon from "@mdi/react";
import { mdiDeleteOutline, mdiContentSave } from "@mdi/js";
import { DropDown } from "../../../../components";

const NewPackagingRow = ({
  newRow,
  packagingMaterials,
  handleInputChange,
  handleDropdownChange,
  handleAddPackagingData,
  setNewRow,
}: any) => {
  if (!newRow) return null;

  return (
    <Table.Row>
      <Table.Cell>
        <Input
          fluid
          value={newRow.packageName}
          onChange={(e) =>
            handleInputChange("newRow", "packageName", e.target.value)
          }
        />
      </Table.Cell>
      <Table.Cell>
        <DropDown
          customGridMain="custom-grid-main"
          placeholder="Packaging Material"
          currentData={packagingMaterials}
          defaultValue={
            packagingMaterials?.[newRow.packageMaterial]?.value || 0
          }
          customGridColumn={"customGridColomnType"}
          handleChangeState={(e, { value }) =>
            handleDropdownChange("newRow", "packageMaterial", value)
          }
        />
      </Table.Cell>
      <Table.Cell>
        <Input
          fluid
          type="number"
          min={0}
          value={newRow.materialWeight}
          onChange={(e) =>
            handleInputChange(
              "newRow",
              "materialWeight",
              parseFloat(e.target.value)
            )
          }
        />
      </Table.Cell>
      <Table.Cell>
        <Input
          fluid
          type="number"
          min={0}
          value={newRow.materialCost}
          onChange={(e) =>
            handleInputChange(
              "newRow",
              "materialCost",
              parseFloat(e.target.value)
            )
          }
        />
      </Table.Cell>
      <Table.Cell>
        <Grid>
          <Grid.Column width={8}>
            <div onClick={handleAddPackagingData}>
              <Icon
                className="table-icon"
                color="var(--tableEditIcon)"
                path={mdiContentSave}
                size={1.2}
              />
            </div>
          </Grid.Column>
          <Grid.Column width={8}>
            <div onClick={() => setNewRow(undefined)}>
              <Icon
                className="table-icon"
                color="var(--tableEditIcon)"
                path={mdiDeleteOutline}
                size={1.2}
              />
            </div>
          </Grid.Column>
        </Grid>
      </Table.Cell>
    </Table.Row>
  );
};

export default NewPackagingRow;
