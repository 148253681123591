import React, { useState } from "react";
import {
  AccordionTitleView,
  CommonTable,
  CustomButton,
  DropDown,
  InputText,
  TitleView,
} from "../../../components";
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Grid,
  GridRow,
  Table,
} from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { InputNumber } from "../../../components/InputText/InputNumber";
import { useGetTransportTypesList } from "../../../api/cultivations";
import {
  useDeleteTransportDetails,
  useGetTransportList,
  usePostTransportData,
  usePutTransportDetails,
} from "../../../api/transportDetails";
import { successMessage } from "../../../helpers/ErrorHandler";
import { TransportDetailsView } from "../../../config/constants";
import { MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import ConfirmModal from "../../../components/confirmViewModal/ConfirmModal";

import "./transportDetails.scss";

const TransportationDetails = ({
  ingredientId,
  disabled = false,
}: {
  ingredientId: any;
  disabled?: boolean;
}) => {
  const { data: TransportTypeList } = useGetTransportTypesList();
  const [defaultTransportType, setDefaultTransportType] = useState<any>(1);
  const [enableEdit, setEnableEdit] = useState<boolean>(false);
  const [currentId, setCurrentId] = useState<any>(null);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [selectData, setSelectData] = useState<any>(null);
  const [accordionEnabled, setAccordionEnabled] = useState<boolean>(false);

  const {
    register,
    formState: { errors: errorsTransportUnit },
    handleSubmit: handleSubmitTransportDetails,
    control,
    setValue,
  } = useForm();

  const { data: transportData, refetch: transportDetails } =
    useGetTransportList(ingredientId);

  const { data: transportPostData, mutate: postTransportDetails } =
    usePostTransportData();

  const { mutate: putTransportDetails } = usePutTransportDetails();

  const { mutate: deleteTransportData } = useDeleteTransportDetails();

  const resetTransportData = () => {
    transportDetails();
    setValue("origin", "");
    setValue("deliveryPoint", "");
    setValue("distance", 0);
    setCurrentId(null);
    setEnableEdit(false);
    setDefaultTransportType(1);
  };

  const saveTransportData = (data: any, status: any) => {
    data.transportType = defaultTransportType;
    data.ingredientId = ingredientId;

    if (status === "edit") {
      data.ingredientTransportId = currentId;
      return putTransportDetails(data, {
        onSuccess(data, variables, context) {
          setCurrentId(null);
          successMessage("Transport Details Updated Successfully");
          resetTransportData();
        },
      });
    }

    return postTransportDetails(data, {
      onSuccess(data, variables, context) {
        setCurrentId(null);
        successMessage("Transport Details Save Successfully");
        transportDetails();
        resetTransportData();
      },
    });
  };

  const setTransportDetails = (data: any) => {
    setValue("origin", data.origin);
    setValue("deliveryPoint", data.deliveryPoint);
    setValue("distance", data.distance);
    setCurrentId(data._id);
    setEnableEdit(true);
    setDefaultTransportType(data.transportType);
  };

  const handleDeleteCancel = () => {
    setSelectData(null);
    setVisibleDeleteModal(false);
  };

  const handleDeleteApprove = () => {
    deleteTransportData(selectData?._id, {
      onSuccess(data, variables, context) {
        setSelectData(null);
        setVisibleDeleteModal(false);
        successMessage("Transport Details Deleted Successfully");
        transportDetails();
      },
    });
  };

  const handleClick = () => {
    setAccordionEnabled(!accordionEnabled);
  };

  const loadTableData = () => {
    return transportData?.map((data: any, index: number) => {
      const transportType = TransportTypeList?.find(
        (e: any) => e.value == data.transportType
      );
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data?.origin ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.deliveryPoint || ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{transportType?.text ?? ""}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data?.distance ?? 0}</p>
          </Table.Cell>
          <Table.Cell>
            {!disabled ? (
              <Grid>
                <Grid.Column computer={8} tablet={16} mobile={16}>
                  <MdModeEditOutline
                    cursor={"pointer"}
                    size={24}
                    color="var(--tableEditIcon)"
                    onClick={() => {
                      setTransportDetails(data);
                    }}
                  />
                </Grid.Column>
                <Grid.Column computer={8} tablet={16} mobile={16}>
                  <MdDeleteOutline
                    cursor={"pointer"}
                    size={24}
                    color="var(--tableEditIcon)"
                    onClick={() => {
                      setSelectData(data);
                      setVisibleDeleteModal(true);
                    }}
                  />
                </Grid.Column>
              </Grid>
            ) : null}
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <Accordion>
      <AccordionTitle active={accordionEnabled} onClick={handleClick}>
        <AccordionTitleView
          accordionEnabled={accordionEnabled}
          title={"Transportation Details"}
        />
      </AccordionTitle>
      <AccordionContent active={accordionEnabled}>
        <Grid>
          {!disabled ? (
            <GridRow className="contentMargin">
              <Grid.Column computer={4} tablet={8} mobile={16}>
                <InputText
                  register={register}
                  errors={errorsTransportUnit.packageName}
                  labelName={"From"}
                  placeholder="From"
                  name="origin"
                  required={true}
                  errorMessage="Packaging name is required"
                />
              </Grid.Column>
              <Grid.Column computer={4} tablet={8} mobile={16}>
                <InputText
                  register={register}
                  errors={errorsTransportUnit.packageName}
                  labelName={"To"}
                  placeholder="To"
                  name="deliveryPoint"
                  required={true}
                  errorMessage="Packaging name is required"
                />
              </Grid.Column>
              <Grid.Column computer={3} tablet={8} mobile={16}>
                <DropDown
                  required={true}
                  labelName={"Transportation Type"}
                  placeholder="Select Transportation Type"
                  currentData={TransportTypeList}
                  defaultValue={
                    TransportTypeList?.[defaultTransportType - 1]?.value
                  }
                  customGridColumn={"customGridColomnType"}
                  handleChangeState={(e: any, { value }: any) => {
                    setDefaultTransportType(value);
                  }}
                />
              </Grid.Column>
              <Grid.Column computer={3} tablet={8} mobile={16}>
                <InputNumber
                  control={control}
                  errors={errorsTransportUnit.materialCost}
                  labelName={"Distance"}
                  placeholder="0.00"
                  name="distance"
                  required={true}
                  errorMessage="Material Cost is required"
                />
              </Grid.Column>
              <Grid.Column computer={2} tablet={8} mobile={16}>
                {enableEdit ? (
                  <CustomButton
                    theme="green"
                    customColumnStyle={"addTransport"}
                    title="Update"
                    onClick={handleSubmitTransportDetails((data) => {
                      saveTransportData(data, "edit");
                    })}
                  />
                ) : (
                  <CustomButton
                    theme="green"
                    customColumnStyle={"addTransport"}
                    icon="plus"
                    onClick={handleSubmitTransportDetails((data) => {
                      saveTransportData(data, "add");
                    })}
                  />
                )}
              </Grid.Column>
            </GridRow>
          ) : null}
          {transportData?.length > 0 ? (
            <Grid.Column
              computer={16}
              tablet={16}
              mobile={16}
              className="packagingDetailsView"
            >
              <CommonTable tableHeaderData={TransportDetailsView}>
                {loadTableData()}
              </CommonTable>
            </Grid.Column>
          ) : (
            <p>Transport Details is not available</p>
          )}
        </Grid>
      </AccordionContent>
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          handleDeleteCancel();
        }}
        approve={() => {
          handleDeleteApprove();
        }}
        title="Delete Transport Data"
        subTitle="Are you sure you want to delete transport data?"
      />
    </Accordion>
  );
};

export default TransportationDetails;
