import { useState } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Grid,
  Table,
} from "semantic-ui-react";
import {
  AccordionTitleView,
  CommonTable,
  CustomButton,
  DropDown,
} from "../../../components";
import "./CultivarForm.scss";
import { InputNumber } from "../../../components/InputText/InputNumber";
import { MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import { isEmpty } from "lodash";

const Irrigation = ({
  irrigation,
  irrigationData,
  onEditHandler,
  control,
  setVisibleDeleteModal,
  setDeleteValue,
  setIrrigation,
  powerSource,
  powerSourceData,
  errors,
  cultivationType,
  setPowerSource,
  isCheckPercentageIrrigation,
  onEdit,
  editTableData,
  cancelEdit,
  onAddClickIrrigationHandler,
  irrigationListData,
  IrrigationTableHeader,
}: any) => {
  const [accordionEnabled, setAccordionEnabled] = useState<boolean>(false);
  const [enableUpdateButton, setEnableUpdateButton] = useState<any>(false);

  const handleClick = () => {
    setAccordionEnabled(!accordionEnabled);
  };
  return cultivationType === "Supplier" ? (
    <Accordion>
      <AccordionTitle active={accordionEnabled} onClick={handleClick}>
        <AccordionTitleView
          accordionEnabled={accordionEnabled}
          title={"Irrigation Details"}
        />
      </AccordionTitle>
      <AccordionContent active={accordionEnabled}>
        <div>
          <Grid>
            <Grid.Column computer={3} tablet={8} mobile={16}>
              <DropDown
                labelName={"Irrigation Method"}
                placeholder="Select Irrigation Method"
                customGridColumn={"customDropDownGrid"}
                defaultValue={irrigation}
                currentData={irrigationData ? irrigationData : []}
                handleChangeState={(e: any, { value }: any) => {
                  setIrrigation(value);
                }}
                search
                key={"irrigation_name"}
              />
            </Grid.Column>
            <Grid.Column computer={3} tablet={8} mobile={16}>
              <DropDown
                labelName={"Power Source"}
                placeholder="Select Power Source"
                customGridColumn={"customDropDownGrid"}
                defaultValue={powerSource}
                currentData={powerSourceData}
                handleChangeState={(e: any, { value }: any) => {
                  setPowerSource(value);
                }}
                search
                key={"power_source"}
              />
            </Grid.Column>
            <Grid.Column computer={3} tablet={6} mobile={16}>
              <InputNumber
                transform={{
                  input: (value: any) =>
                    isNaN(value) || value === 0 ? "" : value.toString(),
                  output: (e: any) => {
                    return e;
                  },
                }}
                control={control}
                labelName={"Water Amount (m3)"}
                placeholder="Water Amount (m3)"
                name="waterAmount"
                errors={errors?.waterAmount}
              />
            </Grid.Column>
            <Grid.Column computer={3} tablet={6} mobile={16}>
              <InputNumber
                transform={{
                  input: (value: any) =>
                    isNaN(value) || value === 0 ? "" : value.toString(),
                  output: (e: any) => {
                    return e;
                  },
                }}
                control={control}
                labelName={"Applied Portion ( % )"}
                placeholder="Applied Portion ( % )"
                name="appliedPortion"
                errors={errors?.appliedPortion}
                otherValidation={{
                  validate: {
                    valueCheck: (v: any) =>
                      isCheckPercentageIrrigation(v) ||
                      "Applied Portion is not valid",
                  },
                }}
              />
            </Grid.Column>
            <Grid.Column computer={2} tablet={2} mobile={16}>
              {onEdit ? (
                <>
                  <Grid>
                    <Grid.Column computer={8}>
                      <CustomButton
                        theme="green"
                        title="edit"
                        customColumnStyle={"plusButton"}
                        onClick={() => editTableData("Irrigation")}
                      />
                    </Grid.Column>

                    <Grid.Column computer={8}>
                      <CustomButton
                        theme="green"
                        title="cancel"
                        customColumnStyle={"plusButton"}
                        onClick={() => cancelEdit("Irrigation")}
                      />
                    </Grid.Column>
                  </Grid>
                </>
              ) : (
                <CustomButton
                  theme="green"
                  title={enableUpdateButton ? "Update" : ""}
                  icon={!enableUpdateButton ? "plus" : null}
                  disabled={isEmpty(irrigation) || isEmpty(powerSource)}
                  customColumnStyle={"plusButton"}
                  onClick={() => {
                    onAddClickIrrigationHandler();
                    setEnableUpdateButton(false);
                  }}
                />
              )}
            </Grid.Column>
          </Grid>

          {irrigationListData?.length > 0 && (
            <Grid.Column
              computer={16}
              tablet={16}
              mobile={16}
              className="pesticideTable"
            >
              <CommonTable
                tableHeaderData={IrrigationTableHeader}
                className="pesticideTable"
              >
                {irrigationListData?.map((item: any, index: any) => {
                  return (
                    <Table.Row key={`pesticide_${item?.id}`}>
                      <Table.Cell>
                        <p>
                          {irrigationData?.find(
                            (e: any) => e.value == item.irrigationMethodId
                          ).text ?? ""}
                        </p>
                      </Table.Cell>
                      <Table.Cell>
                        <p>
                          {powerSourceData?.find(
                            (e: any) => e.value == item.powerSourceId
                          ).text ?? ""}
                        </p>
                      </Table.Cell>
                      <Table.Cell>
                        <p>{item?.waterAmount ?? ""}</p>
                      </Table.Cell>
                      <Table.Cell>
                        <p>{item?.appliedPortion ?? ""}</p>
                      </Table.Cell>
                      <Table.Cell>
                        <Grid>
                          <Grid.Column computer={8} tablet={16} mobile={16}>
                            <MdModeEditOutline
                              cursor={"pointer"}
                              size={24}
                              color="var(--tableEditIcon)"
                              onClick={() => {
                                onEditHandler(item, "Irrigation", index);
                                setEnableUpdateButton(true);
                              }}
                            />
                          </Grid.Column>
                          <Grid.Column computer={8} tablet={16} mobile={16}>
                            <MdDeleteOutline
                              cursor={"pointer"}
                              size={24}
                              color="var(--tableEditIcon)"
                              onClick={() => {
                                setVisibleDeleteModal(true);
                                setDeleteValue({
                                  type: "Irrigation",
                                  item: item ?? "",
                                  index: index,
                                });
                              }}
                            />
                          </Grid.Column>
                        </Grid>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </CommonTable>
            </Grid.Column>
          )}
        </div>
      </AccordionContent>
    </Accordion>
  ) : null;
};

export default Irrigation;
