import {
    Placeholder,
    PlaceholderLine,
    PlaceholderParagraph
  } from "semantic-ui-react";

export const LoadingPlaceholder = () => (
    <Placeholder fluid>
      <PlaceholderParagraph>
        <PlaceholderLine />
        <PlaceholderLine />
        <PlaceholderLine />
        <PlaceholderLine />
        <PlaceholderLine />
      </PlaceholderParagraph>
      <PlaceholderParagraph>
        <PlaceholderLine />
        <PlaceholderLine />
        <PlaceholderLine />
      </PlaceholderParagraph>
    </Placeholder>
  );