import React, { useState } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Image,
} from "semantic-ui-react";

import { AccordionTitleView, TitleView } from "../../../components";
import { images } from "../../../assets/images";
import Packaging from "./Packaging";

import "./IngredientRegister.scss";
import { useGetPackagingTypes } from "../../../api/processGate/packaging";

const IngredientPackagingView = ({
  ingredientId,
  supplierId,
  refetchPackagingData,
  ingredientPackageData,
  dataView,
}: any) => {
  const [activeIndex, setActiveIndex] = useState(1);
  const [accordionEnabled, setAccordionEnabled] = useState<boolean>(false);

  const {
    data: packagingTypeData,
    status: packagingTypeLoad,
    fetchStatus: packagingFetch,
  } = useGetPackagingTypes();

  const handleClick = () => {
    setAccordionEnabled(!accordionEnabled);
  };

  return (
    <Accordion>
      <AccordionTitle active={accordionEnabled} onClick={handleClick}>
        <AccordionTitleView
          accordionEnabled={accordionEnabled}
          title={"Packaging information"}
        />
      </AccordionTitle>
      <AccordionContent active={accordionEnabled}>
        <div>
          <Accordion>
            <AccordionTitle
              active={activeIndex === 1}
              index={1}
              onClick={() => {
                setActiveIndex(activeIndex === 1 ? 0 : 1);
              }}
            >
              <p className="packagingTitleText">
                Please add information about how your ingredients are packaged
                at each level following the GS1 guidelines. Use this image as a
                reference.
              </p>
            </AccordionTitle>
            <AccordionContent active={activeIndex === 1}>
              <Image src={images.PackingProcess} />
            </AccordionContent>
          </Accordion>
          <Packaging
            packagingId={ingredientPackageData?._id || null}
            packagingType="single-unit"
            unitSizes={ingredientPackageData?.unitSize || 0}
            totalWeightOfItem={ingredientPackageData?.totalWeightBaseUnit || 0}
            packagingData={ingredientPackageData?.singleUnitPackaging || []}
            packagingTypeData={packagingTypeData ? packagingTypeData : []}
            ingredientId={ingredientId}
            supplierId={supplierId}
            refetchPackagingData={refetchPackagingData}
            dataView={dataView}
          />
          <Packaging
            packagingId={ingredientPackageData?._id || null}
            packagingType="sales-unit"
            unitSizes={ingredientPackageData?.numberOfBaseUnits || 0}
            totalWeightOfItem={
              ingredientPackageData?.totalWeightSalesOfPack || 0
            }
            packagingData={ingredientPackageData?.salesUnitPackaging || []}
            packagingTypeData={packagingTypeData ? packagingTypeData : []}
            ingredientId={ingredientId}
            supplierId={supplierId}
            refetchPackagingData={refetchPackagingData}
            dataView={dataView}
          />
          <Packaging
            packagingId={ingredientPackageData?._id || null}
            packagingType="bulk-unit"
            unitSizes={ingredientPackageData?.numberOfPacks || 0}
            totalWeightOfItem={ingredientPackageData?.totalWeightBulkPack || 0}
            packagingData={ingredientPackageData?.bulkUnitPackaging || []}
            packagingTypeData={packagingTypeData ? packagingTypeData : []}
            ingredientId={ingredientId}
            supplierId={supplierId}
            refetchPackagingData={refetchPackagingData}
            dataView={dataView}
          />
          <Packaging
            packagingId={ingredientPackageData?._id || null}
            packagingType="transport-unit"
            unitSizes={ingredientPackageData?.numberOfCases || 0}
            totalWeightOfItem={
              ingredientPackageData?.totalWeightTransportUnitPack || 0
            }
            packagingData={ingredientPackageData?.transportUnitPackaging || []}
            packagingTypeData={packagingTypeData ? packagingTypeData : []}
            ingredientId={ingredientId}
            supplierId={supplierId}
            refetchPackagingData={refetchPackagingData}
            dataView={dataView}
          />
        </div>
      </AccordionContent>
    </Accordion>
  );
};

export default IngredientPackagingView;
