import React, { useEffect, useState } from "react";
import { Table, Input, Grid } from "semantic-ui-react";
import Icon from "@mdi/react";
import { mdiPencil, mdiDeleteOutline, mdiContentSave } from "@mdi/js";
import "./packagingTable.scss";
import { calculateTotalWeight } from "../../utils/calculateTotalWeight";
import { DropDown } from "../../../../components/selectDropDown/DropDown";
import { CustomButton } from "../../../../components/button/Button";
import { packagingFieldMap } from "../../../../config/constants";
import NewPackagingRow from "./NewPackagingRow";

interface PackagingTableProps {
  tableData: PackagingTableData[];
  editable?: boolean;
  originalData?: PackagingTableData[];
  setNewPackagingData?: React.Dispatch<
    React.SetStateAction<PackagingData | undefined>
  >;
  field?: PackagingField;
  packagingMaterials: PackagingMaterial[];
  isView?: boolean;
  createPackaging?: (data: PackagingData) => void;
  updatePackaging?: (data: PackagingData) => void;
  deletePackaging?: (data: any) => void;
}

const PackagingTable: React.FC<PackagingTableProps> = ({
  tableData,
  editable,
  setNewPackagingData,
  field,
  originalData,
  packagingMaterials,
  isView,
  createPackaging,
  updatePackaging,
  deletePackaging,
}) => {
  const [editIndex, setEditIndex] = useState<number | undefined>(undefined);
  const [editRow, setEditRow] = useState<PackagingTableData | undefined>(
    undefined
  );
  const [newRow, setNewRow] = useState<PackagingTableData | undefined>();

  useEffect(() => {
    if (editIndex !== undefined) {
      setEditRow(tableData[editIndex]);
    }
  }, [editIndex]);

  const handleInputChange = (rowType: "editRow" | "newRow", field: string, value: any) => {
    if (rowType === "editRow" && editRow) {
      setEditRow({ ...editRow, [field]: value });
    } else if (rowType === "newRow" && newRow) {
      setNewRow({ ...newRow, [field]: value });
    }
  };

  const handleDropdownChange = (rowType: "editRow" | "newRow", field: string, value: any) => {
    let getIndex = packagingMaterials?.findIndex(
      (e: any) => e?.value === value
    );
    handleInputChange(rowType, field, getIndex);
  };

  const handleAddPackagingData = () => {
    if (!setNewPackagingData || !newRow || !field) return;
    setNewPackagingData((prev: any) => {
      if (prev) {
        const newData = [...prev[field]];
        newData.push(newRow);
        return calculateTotalWeight({
          ...prev,
          [field]: newData,
        });
      }
      return prev;
    });
    if (createPackaging) {
      createPackaging({
        ...newRow,
        packagingType: packagingFieldMap[field as PackagingField],
      } as any);
    }
    setNewRow(undefined);
  };

  const handleUpdatePackagingData = (index: number) => {
    if (!setNewPackagingData || !editRow || !field) return;
    setNewPackagingData((prev: any) => {
      if (prev) {
        const newData = [...prev[field]];
        newData[index] = editRow;
        return calculateTotalWeight({
          ...prev,
          [field]: newData,
        });
      }
      return prev;
    });
    if (updatePackaging) {
      updatePackaging({
        ...editRow,
        packagingType: packagingFieldMap[field as PackagingField],
      } as any);
    }
    setEditIndex(undefined);
  };

  const handleDeletePackagingData = (index: number) => {
    if (!setNewPackagingData || !field) return;
    setNewPackagingData((prev: any) => {
      if (prev) {
        const newData = [...prev[field]];
        const deleted = newData.splice(index, 1);
        if (deletePackaging && deleted.length > 0) {
          deletePackaging({
            ...deleted[0],
            packagingType: packagingFieldMap[field as PackagingField],
            subPackagingId: deleted[0]._id,
          });
        }
        return calculateTotalWeight({
          ...prev,
          [field]: newData,
        });
      }
      return prev;
    });
  };

  const renderActions = (index: number) => {
    if (!editable || isView) {
      return null;
    }
    return (
      <Table.Cell>
        {editIndex === index ? (
          <div onClick={() => handleUpdatePackagingData(index)}>
            <Icon
              className="table-icon"
              color="var(--tableEditIcon)"
              path={mdiContentSave}
              size={1.2}
            />
          </div>
        ) : (
          <Grid>
            <Grid.Column width={8}>
              <div onClick={() => setEditIndex(index)}>
                <Icon
                  className="table-icon"
                  color="var(--tableEditIcon)"
                  path={mdiPencil}
                  size={1.2}
                />
              </div>
            </Grid.Column>
            <Grid.Column width={8}>
              <div onClick={() => handleDeletePackagingData(index)}>
                <Icon
                  className="table-icon"
                  color="var(--tableEditIcon)"
                  path={mdiDeleteOutline}
                  size={1.2}
                />
              </div>
            </Grid.Column>
          </Grid>
        )}
      </Table.Cell>
    );
  };

  return (
    <>
      {editable ? (
        <CustomButton
          customColumnStyle="p-0 m-0 packaging-add-button"
          theme="green"
          title="Add New"
          disabled={isView}
          onClick={() => {
            setNewRow({
              packageName: "",
              packageMaterial: 0,
              materialWeight: 0,
              materialCost: 0,
            });
          }}
        />
      ) : (
        <div className={isView ? "empty-block-view" : "empty-block"}></div>
      )}
      <Table
        singleLine
        className={editable ? "editable-table" : "non-editable-table"}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Packaging name</Table.HeaderCell>
            <Table.HeaderCell>Packaging material</Table.HeaderCell>
            <Table.HeaderCell>Material weight (kg)</Table.HeaderCell>
            <Table.HeaderCell>
              Material Cost <br /> per unit
            </Table.HeaderCell>
            {editable && !isView && (
              <Table.HeaderCell>Actions</Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {tableData.map((item, index) => {
            const isEditing = editIndex === index;
            const originalItem: { [key: string]: any } =
              originalData?.[index] || {};

            const isEdited = (
              field:
                | "materialWeight"
                | "packageName"
                | "packageMaterial"
                | "materialCost"
            ) => editable && originalItem[field] !== item[field];

            return (
              <Table.Row key={index}>
                <Table.Cell>
                  {isEditing ? (
                    <Input
                      fluid
                      value={editRow?.packageName || ""}
                      onChange={(e) =>
                        handleInputChange(
                          "editRow",
                          "packageName",
                          e.target.value
                        )
                      }
                    />
                  ) : (
                    <p className={isEdited("packageName") ? "edited" : ""}>
                      {item.packageName}
                    </p>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {isEditing ? (
                    <DropDown
                      customGridMain="custom-grid-main"
                      placeholder="Packaging Material"
                      currentData={packagingMaterials}
                      defaultValue={
                        packagingMaterials?.[editRow?.packageMaterial as number]
                          ?.value ?? 0
                      }
                      customGridColumn="customGridColomnType"
                      handleChangeState={(e, { value }) => {
                        handleDropdownChange(
                          "editRow",
                          "packageMaterial",
                          value
                        );
                      }}
                    />
                  ) : (
                    <p className={isEdited("packageMaterial") ? "edited" : ""}>
                      {packagingMaterials?.[item.packageMaterial || 0]?.text ||
                        ""}
                    </p>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {isEditing ? (
                    <Input
                      fluid
                      type="number"
                      min={0}
                      value={editRow?.materialWeight || ""}
                      onChange={(e) =>
                        handleInputChange(
                          "editRow",
                          "materialWeight",
                          parseFloat(e.target.value)
                        )
                      }
                    />
                  ) : (
                    <p className={isEdited("materialWeight") ? "edited" : ""}>
                      {item.materialWeight}
                    </p>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {isEditing ? (
                    <Input
                      fluid
                      type="number"
                      min={0}
                      value={editRow?.materialCost || ""}
                      onChange={(e) =>
                        handleInputChange(
                          "editRow",
                          "materialCost",
                          parseFloat(e.target.value)
                        )
                      }
                    />
                  ) : (
                    <p className={isEdited("materialCost") ? "edited" : ""}>
                      {item.materialCost}
                    </p>
                  )}
                </Table.Cell>
                {renderActions(index)}
              </Table.Row>
            );
          })}
          {newRow && (
            <NewPackagingRow
              newRow={newRow}
              packagingMaterials={packagingMaterials}
              handleInputChange={handleInputChange}
              handleDropdownChange={handleDropdownChange}
              handleAddPackagingData={handleAddPackagingData}
              setNewRow={setNewRow}
            />
          )}
        </Table.Body>
      </Table>
    </>
  );
};

export default PackagingTable;
