import React, { useEffect, useMemo, useState } from "react";
import { ModalHeader, ModalContent, Modal, Grid } from "semantic-ui-react";
import { CustomButton, DropDown, InputText } from "../../../../../components";
import { useForm } from "react-hook-form";
import { useGetFacilityWholeList } from "../../../../../api/cultivations";
import { errorView } from "../../../../../helpers/ErrorHandler";

interface ProcessGateFormModalProps {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  newNode?: any;
  edit?: boolean;
  processGateName?: any;
  updateProcessGate: (
    processGateName: string,
    newNode: any,
    closeModal: () => void
  ) => void;
  createProcessGate: (
    processGateName: string,
    newNode: any,
    closeModal: () => void
  ) => void;
}

const ProcessGateFormModal: React.FC<ProcessGateFormModalProps> = ({
  modalOpen,
  setModalOpen,
  newNode,
  edit,
  processGateName,
  updateProcessGate,
  createProcessGate,
}) => {
  const [open, setOpen] = useState(false);
  const [facilityId, setFacilityId] = useState(""); 

  // Fetch facility list
  const { data: facilityWholeList, isSuccess: isFacilitySuccess } =
    useGetFacilityWholeList("FACTORY");

  // Effect to initialize form data when the modal opens
  useEffect(() => {
    if (edit && newNode?.data) {
      setValue("processGateName", processGateName); 
      setValue("facilityName", newNode.data.facilityId); 
      setFacilityId(newNode.data.facilityId || ""); 
    }
    setOpen(modalOpen);
  }, [modalOpen, processGateName, newNode, facilityWholeList]);

  const closeModal = () => {
    setOpen(false);
    setModalOpen(false);
  };

  const defaultValues = useMemo(() => {
    return {
      processGateName: "",
      facilityName: "",
    };
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "all",
    defaultValues,
  });

  const onSubmit = (data: { processGateName: string; facilityName: string }) => {
    if(!facilityId){
      return errorView("Facility name is required");
    }
    const modifiedNode = {
      ...newNode,
      data: {
        ...newNode?.data,
        label: `Process Gate (${data.processGateName})`,
        facilityId: facilityId,
      },
    };
    if (edit) {
      updateProcessGate(data.processGateName, modifiedNode, closeModal);
    } else {
      createProcessGate(data.processGateName, modifiedNode, closeModal);
    }
  };

  return (
    <Modal
      closeIcon={edit ? true : false}
      onClose={closeModal}
      open={open}
      centered={false}
      dimmer="blurring"
    >
      <ModalHeader>Process Gate</ModalHeader>
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Grid.Column computer={12} tablet={12} mobile={12}>
              <InputText
                register={register}
                labelName="Process Gate Name"
                name="processGateName"
                errors={errors.processGateName}
                required
                errorMessage="Process Gate Name is required"
              />
            </Grid.Column>

            <Grid.Column computer={8} tablet={8} mobile={16}>
              <DropDown
                labelName={"Facility Name*"}
                placeholder="Select Facility Name"
                currentData={facilityWholeList}
                defaultValue={facilityId} 
                validate={true}
                required={true}
                errorMessage={"Facility name is required"}
                handleChangeState={(e: any, { value }: any) => {
                  setFacilityId(value); 
                  setValue("facilityName", value); 
                }}
              />
            </Grid.Column>

            <Grid.Column computer={4} tablet={4} mobile={4}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CustomButton theme="green" title={"Update"} type="submit" />
              </div>
            </Grid.Column>
          </Grid>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default ProcessGateFormModal;

