import React from "react";
import { Dropdown, Pagination, PaginationProps } from "semantic-ui-react";

import "./tableFooter.scss";

const PAGE_SIZES = [
  {
    key: 5,
    text: `5`,
    value: 5,
  },
  {
    key: 10,
    text: `10`,
    value: 10,
  },
  {
    key: 20,
    text: `20`,
    value: 20,
  },
  {
    key: 50,
    text: `50`,
    value: 50,
  },
];

interface TableFooterProps {
  activePage: number;
  totalPages: number;
  pageSize?: number;
  pageSizes?: any[];
  handlePaginationChange: (
    e: any,
    { activePage, totalPages }: PaginationProps
  ) => void;
  changePageSize?: (pageSize: number) => void;
}

const TableFooter: React.FC<TableFooterProps> = ({
  totalPages,
  activePage,
  handlePaginationChange,
  pageSize,
  pageSizes = PAGE_SIZES,
  changePageSize,
}) => {
  return (
    <div className="table-footer">
      <Pagination
        activePage={activePage}
        onPageChange={handlePaginationChange}
        size="mini"
        totalPages={totalPages}
      />
      {pageSize && changePageSize && (
        <Dropdown
          compact
          value={pageSize}
          selection
          options={pageSizes}
          onChange={(e, { value }: any) => {
            if (changePageSize) changePageSize(value);
          }}
        />
      )}
    </div>
  );
};

export default TableFooter;
