import React, { useEffect, useState } from "react";
import Icon from "@mdi/react";
import {
  mdiArrowDownDropCircleOutline,
  mdiArrowRightDropCircleOutline,
  mdiFileSearchOutline,
  mdiPencil,
  mdiDeleteOutline,
} from "@mdi/js";
import {
  Grid,
  PaginationProps,
  Placeholder,
  PlaceholderLine,
  PlaceholderParagraph,
  Table,
} from "semantic-ui-react";

import "./SimulationTable.scss";
import TableFooter from "../../packagingSimulation/component/tableFooter";
import { warningMessage } from "../../../helpers/ErrorHandler";
import {
  SimulationDataTable,
  SimulationImpactFarm,
  SimulationImpactProcess,
} from "../../../config/constants";
import { CommonTable } from "../../../components";
import PaginationView from "../../../components/pagination/Pagination";

interface SimulationTableProps {
  data: DrawingSimulationData[] | undefined;
  onEdit: (data: DrawingSimulationData, isView?: boolean) => void;
  onDelete: (data: DrawingSimulationData) => void;
  setPageNumber: (pageNumber: number) => void;
  setPageSize: (pageSize: number) => void;
  pageNumber: number;
  pageSize: number;
  totalSimulations: number;
  isGetProductSimulationsLoading: boolean;
}

type ClimateImpactData = {
  processGates?: any[];
  farmGates?: any[];
  externalStorages?: { storage?: any[] };
  externalTransports?: { transport?: any[] };
};

const LoadingComponent = () => (
  <Placeholder fluid>
    <PlaceholderParagraph>
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
    </PlaceholderParagraph>
    <PlaceholderParagraph>
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
    </PlaceholderParagraph>
  </Placeholder>
);

const SimulationTable: React.FC<SimulationTableProps> = ({
  data,
  onEdit,
  onDelete,
  setPageNumber,
  setPageSize,
  pageNumber,
  pageSize,
  totalSimulations,
  isGetProductSimulationsLoading,
}) => {
  const [totalPages, setTotalPages] = useState(10);
  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  useEffect(() => {
    setTotalPages(Math.ceil(totalSimulations / pageSize));
  }, [totalSimulations, pageSize]);

  const handlePaginationChange = (
    e: any,
    { activePage, totalPages }: PaginationProps
  ) => {
    if (typeof activePage === "number") setPageNumber(activePage);
    if (typeof totalPages === "number") setTotalPages(totalPages);
  };

  const getCurrentImpact = (
    originalImpact: number,
    simulatedImpact: number
  ) => {
    return simulatedImpact
      ? Math.fround(
          ((simulatedImpact - originalImpact) / originalImpact) * 100
        ).toFixed(4)
      : "";
  };

  const handleRowClick = (rowId: number) => {
    const currentExpandedRows = expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter((id) => id !== rowId)
      : currentExpandedRows.concat(rowId);
    setExpandedRows(newExpandedRows);
  };

  const renderItemCaret = (rowId: number) => {
    const currentExpandedRows = expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    if (isRowCurrentlyExpanded) {
      return mdiArrowDownDropCircleOutline;
    } else {
      return mdiArrowRightDropCircleOutline;
    }
  };

  const addName = (items: any[], name: string, defaultName: any = "") =>
    items.length > 0
      ? items.map((item) => ({
          ...item,
          name: `${defaultName} ${item?.[name] || ""}`,
        }))
      : [];

  const handleGate = (
    data: any[],
    gateNameKey: string,
    fields: { key: string; name: string }[]
  ): any[] => {
    return data.flatMap((item: any) => {
      const gateName = item[gateNameKey];
      return fields.flatMap(({ key, name }) =>
        addName(item[key] || [], name, gateName)
      );
    });
  };

  const handleProcessGate = (data: any): any[] => {
    return handleGate(data, "processGateName", SimulationImpactProcess);
  };

  const handleFarmGate = (data: any): any[] => {
    return handleGate(data, "farmGateName", SimulationImpactFarm);
  };

  const handleExternalStorageAndTransport = (
    data: any,
    valueType?: string | null,
    defaultName?: string
  ): any[] => {
    if (!Array.isArray(data)) {
      return [];
    }

    if (typeof valueType !== "string") {
      return [];
    }
    const externalData = data.flatMap((item: any) => {
      const nestedArray = item?.[valueType] || item;

      return Array.isArray(nestedArray)
        ? nestedArray.map((currentData: any) => currentData)
        : [];
    });

    return addName(externalData, `${valueType}`, defaultName);
  };

  const getClimateImpactData = (data: ClimateImpactData): any[] => {
    const {
      processGates = [],
      farmGates = [],
      externalStorages = [],
      externalTransports = [],
    } = data;

    return [
      ...handleProcessGate(processGates),
      ...handleFarmGate(farmGates),
      ...handleExternalStorageAndTransport(
        externalStorages || [],
        "storage",
        "External Storage"
      ),
      ...handleExternalStorageAndTransport(
        externalTransports || [],
        "transport",
        "External Transport"
      ),
    ];
  };

  const renderItem = (item: DrawingSimulationData, index: number) => {
    const climateData = getClimateImpactData(
      item?.simulationProductSummary || []
    );
    const itemRows: any = [
      <Table.Row key={`row-data-${index}`}>
        <Table.Cell
          onClick={() => {
            climateData.length > 0
              ? handleRowClick(index)
              : warningMessage("Simulation Data not available");
          }}
        >
          <Icon
            className="table-icon"
            color="var(--tableEditIcon)"
            path={renderItemCaret(index)}
            size={1.2}
          />
        </Table.Cell>
        <Table.Cell>{item?.drawingName}</Table.Cell>
        <Table.Cell>
          {item?.productSummary?.totalImpact?.climateChange?.measure}
        </Table.Cell>
        <Table.Cell>
          {item?.simulationProductSummary?.totalImpact?.climateChange?.measure}
        </Table.Cell>
        <Table.Cell>
          {getCurrentImpact(
            item?.productSummary?.totalImpact?.climateChange?.measure,
            item?.simulationProductSummary?.totalImpact?.climateChange?.measure
          )}
        </Table.Cell>
        <Table.Cell>
          <Grid>
            <Grid.Column computer={3} tablet={3} mobile={3}>
              <div
                onClick={() => {
                  onEdit(item, true);
                }}
              >
                <Icon
                  className="table-icon"
                  color="var(--tableEditIcon)"
                  path={mdiFileSearchOutline}
                  size={1.2}
                />
              </div>
            </Grid.Column>
            <Grid.Column computer={3} tablet={3} mobile={3}>
              <div
                onClick={() => {
                  onEdit(item);
                }}
              >
                <Icon
                  className="table-icon"
                  color="var(--tableEditIcon)"
                  path={mdiPencil}
                  size={1.2}
                />
              </div>
            </Grid.Column>
            <Grid.Column computer={3} tablet={3} mobile={3}>
              <div
                onClick={() => {
                  onDelete(item);
                }}
              >
                <Icon
                  className="table-icon"
                  color="var(--tableEditIcon)"
                  path={mdiDeleteOutline}
                  size={1.2}
                />
              </div>
            </Grid.Column>
          </Grid>
        </Table.Cell>
      </Table.Row>,
    ];

    if (expandedRows.includes(index)) {
      climateData.map((data: any) => {
        return itemRows.push(
          <Table.Row key={"row-expanded-" + index}>
            <Table.Cell></Table.Cell>
            <Table.Cell>{data?.name}</Table.Cell>
            <Table.Cell>{data?.originalCalculatedClimateImpact}</Table.Cell>
            <Table.Cell>{data?.calculatedClimateImpact}</Table.Cell>
            <Table.Cell>
              {getCurrentImpact(
                data?.originalCalculatedClimateImpact,
                data?.calculatedClimateImpact
              )}
            </Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
        );
      });
    }

    return itemRows;
  };

  return (
    <Grid>
      <Grid.Column computer={16}>
        <CommonTable tableHeaderData={SimulationDataTable}>
          {isGetProductSimulationsLoading && (
            <Table.Row>
              <Table.Cell colSpan="7">
                <LoadingComponent />
              </Table.Cell>
            </Table.Row>
          )}
          {!isGetProductSimulationsLoading &&
            data?.map((item: DrawingSimulationData, index) =>
              renderItem(item, index)
            )}
        </CommonTable>
      </Grid.Column>
      <Grid.Column computer={16}>
        <PaginationView
          changePageSize={setPageSize}
          pageSize={pageSize}
          totalPages={totalPages}
          currentPageNo={pageNumber}
          currentSelectPage={handlePaginationChange}
        />
      </Grid.Column>
    </Grid>
  );
};

export default SimulationTable;
