import {
  ReactFlowProvider,
  Node,
  Edge,
  EdgeChange,
  NodeChange,
} from "reactflow";
import {
  Dispatch,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Sidebar,
  Segment,
  Button,
  Icon,
  Popup,
  Dimmer,
} from "semantic-ui-react";
import {
  useLocation,
  useParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import "./SimulateDrawingTool.scss";
import SimulateCanvasMain from "./SimulateCanvasMain";
import AddingItemsPanel from "./AddingNodes/AddingItemsPanel";
import UpdateNodeModal from "./UpdateNodes/UpdateNodeModal";
import { useGetDrawingToolData } from "../../api/drawingTool";
import {
  useCreateSimulateDrawing,
  useGetSimulationDrawingById,
  useUpdateSimulateDrawing,
  usePutSimulationMappingDataToQueue,
} from "../../api/simulation";
import { nodeCatagories } from "../../config/drawingConstants";
import useNodesStateSynced from "./Hooks/useNodesStateSynced";
import useEdgesStateSynced from "./Hooks/useEdgesStateSynced";
import calculatorLoading from "../../assets/animations/CalculatorLoading.json";
import Lottie from "lottie-react";
import SimulationDrawingSaveModal from "./SimulationDrawingSaveModal";
import DrawingErrorsModal from "../drawing_tool/DrawingErrorsModal";

interface DrawingsState {
  nodeItemId: any | null;
  productId: string | undefined;
  chartNodes: Node<any>[];
  chartEdges: Edge<any>[];
  salesUnitId: string | undefined;
  productData: any;
  setChartNodes: Dispatch<Node[]>;
  setChartEdges: Dispatch<Edge[]>;
  onNodesChange: Dispatch<NodeChange[]>;
  onEdgesChange: Dispatch<EdgeChange[]>;
  setNodeItem: Dispatch<any>;
  updateChartGroup: (groupClassName: string, node: Node) => void;
  saveDrawing: (chartEdges: any, chartNodes: any) => void;
  saveProductData: Dispatch<any>;
}

const initialState: DrawingsState = {
  nodeItemId: null,
  productId: undefined,
  chartNodes: [],
  chartEdges: [],
  salesUnitId: undefined,
  productData: undefined,
  setChartNodes: () => {},
  setChartEdges: () => {},
  onNodesChange: () => {},
  onEdgesChange: () => {},
  setNodeItem: () => {},
  updateChartGroup: () => {},
  saveDrawing: () => {},
  saveProductData: () => {},
};

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const DrawingContext = createContext(initialState);

const SimulateDrawingTool = () => {
  // Drawing States
  const [chartNodes, setChartNodes, onNodesChange] = useNodesStateSynced();
  const [chartEdges, setChartEdges, onEdgesChange] = useEdgesStateSynced();
  const [nodeItemId, setNodeItem] = useState<any>(null);
  const [productData, saveProductData] = useState<any>({});
  const [isVisible, setIsVisible] = useState(false);
  const [isOpenSavingModal, setIsOpenSavingModal] = useState(false);
  const [simulationDrawingId, setSimulationDrawingId] = useState<string | null>(
    null
  );
  const [queryParam, setQueryParam] = useSearchParams();
  const navigate = useNavigate();

  const [calculateModalOpen, setCalculateModalOpen] = useState<boolean>(false);
  const [putDataToQueueErrors, setPutDataToQueueErrors] = useState<string[]>(
    []
  );

  const { productId, salesUnitId } = useParams();
  const query = useQuery();

  // Get Original Product Drawing
  const { data: originalDrawingToolData, isLoading: isDrawingToolLoading } =
    useGetDrawingToolData(salesUnitId);

  // Get Simulated Product Drawing
  const {
    data: simulateDrawingToolData,
    isLoading: isSimulateDrawingToolLoading,
  } = useGetSimulationDrawingById(simulationDrawingId);

  // Create Simulated Product Drawing
  const { mutate: createSimulateDrawing, isLoading: isLoadingCreateDrawing } =
    useCreateSimulateDrawing();

  // Update Simulated Product Drawing
  const { mutate: updateSimulateDrawing, isLoading: isLoadingUpdateDrawing } =
    useUpdateSimulateDrawing();

  const {
    isLoading: isLoadingPutDataToQueue,
    error: putDataToQueueError,
    mutate: putMappingToolDataToQueue,
  } = usePutSimulationMappingDataToQueue();

  useEffect(() => {
    const simulateDrawingId = query.get("simulateDrawingId");
    if (!simulateDrawingId) {
      setIsOpenSavingModal(true);
    }
    setSimulationDrawingId(simulateDrawingId);
  }, []);

  useEffect(() => {
    if (putDataToQueueError?.errors) {
      setPutDataToQueueErrors(putDataToQueueError.errors);
    }
  }, [putDataToQueueError]);

  useEffect(() => {
    if (originalDrawingToolData) {
      setChartNodes(originalDrawingToolData?.chartNodes || []);
      setChartEdges(originalDrawingToolData?.chartEdges || []);
    }
    if (simulateDrawingToolData) {
      setChartNodes(simulateDrawingToolData?.chartNodes || []);
      setChartEdges(simulateDrawingToolData?.chartEdges || []);
    }
  }, [simulateDrawingToolData, originalDrawingToolData]);

  const saveSimulateDrawing = useCallback(
    (chartEdges: any, chartNodes: any, title: string, note: string) => {
      const newDrawing = {
        productId,
        salesUnitId,
        drawingName: title,
        drawingNote: note,
        chartEdges: chartEdges,
        chartNodes: chartNodes,
      };
      createSimulateDrawing(newDrawing, {
        onSuccess: (data) => {
          setSimulationDrawingId(data?._id);
          setQueryParam({
            simulateDrawingId: data?._id,
          });
          setIsOpenSavingModal(false);
        },
      });
    },
    [salesUnitId, productId] //updateDrawing, createDrawing
  );

  const updateSimulateDrawingChart = useCallback(
    (chartEdges: any, chartNodes: any) => {
      const updateDrawing = {
        drawingName: simulateDrawingToolData?.drawingName,
        drawingNote: simulateDrawingToolData?.drawingNote,
        chartEdges: chartEdges,
        chartNodes: chartNodes,
      };
      updateSimulateDrawing({ data: updateDrawing, simulationDrawingId });
    },
    [salesUnitId, productId, simulationDrawingId]
  );

  const updateSimulationAndCalculate = useCallback(
    (
      chartEdges: any,
      chartNodes: any,
      drawingName: string,
      drawingNote: string
    ) => {
      const updateDrawing = {
        drawingName,
        drawingNote,
        chartEdges: chartEdges,
        chartNodes: chartNodes,
      };
      updateSimulateDrawing({ data: updateDrawing, simulationDrawingId });
      putMappingToolDataToQueue(simulationDrawingId, {
        onSuccess: () => {
          navigate(`/simulations/product-simulation?salesUnitId=${salesUnitId}&productId=${productId}`);
        },
      });
    },
    [salesUnitId, productId, simulationDrawingId]
  );

  const saveDrawing = useCallback(() => {
    if (simulationDrawingId) {
      updateSimulateDrawingChart(chartEdges, chartNodes);
      return;
    }
    setIsOpenSavingModal(true);
  }, [salesUnitId, productId, simulationDrawingId, chartEdges, chartNodes]);

  const onClickApprove = useCallback(
    (title: string, note: string) => {
      if (calculateModalOpen) {
        updateSimulationAndCalculate(chartEdges, chartNodes, title, note);
        setCalculateModalOpen(false);
        return;
      }
      saveSimulateDrawing(chartEdges, chartNodes, title, note);
    },
    [chartEdges, chartNodes, calculateModalOpen]
  );

  const closeSaveModal = useCallback(() => {
    if (calculateModalOpen) {
      setCalculateModalOpen(false);
      return;
    }
    setIsOpenSavingModal(false);
    navigate(-1);
  }, [calculateModalOpen]);

  const updateChartGroup = useCallback(
    (groupClassName: string, node: Node) => {
      setChartNodes((nds) => {
        const data = [...nds];
        return data.map((n) => {
          if (n.type !== nodeCatagories.special) {
            return {
              ...n,
              className: groupClassName,
            };
          } else if (n.id === node.id) {
            return {
              ...n,
              position: node.position,
            };
          }
          return { ...n };
        });
      });
    },
    [setChartNodes]
  );

  // Context Provider Values
  const value = {
    chartNodes,
    chartEdges,
    nodeItemId,
    salesUnitId,
    productId,
    productData,
    setChartNodes,
    setChartEdges,
    onEdgesChange,
    onNodesChange,
    setNodeItem,
    updateChartGroup,
    saveDrawing,
    saveProductData,
  };

  if (
    isDrawingToolLoading ||
    isLoadingCreateDrawing ||
    (isSimulateDrawingToolLoading && simulationDrawingId) ||
    isLoadingUpdateDrawing ||
    isLoadingPutDataToQueue
  ) {
    return (
      <Dimmer active>
        <div className="loading-calculation">
          <Lottie animationData={calculatorLoading} loop={true} />
        </div>
      </Dimmer>
    );
  }

  return (
    <DrawingContext.Provider value={value}>
      <ReactFlowProvider>
        <Sidebar.Pushable as={Segment} style={{ overflow: "hidden" }}>
          <AddingItemsPanel
            isVisible={isVisible}
            onClose={() => setIsVisible(!isVisible)}
          />
          <Button
            icon
            color="teal"
            className="open-side-menu-button"
            onClick={() => {
              setIsVisible(!isVisible);
            }}
          >
            <Icon name="angle double right" />
          </Button>
          <SimulateCanvasMain />

          <Popup
            content={`${simulationDrawingId ? "Update" : "Save"} Mapping.`}
            size="mini"
            trigger={
              <Button
                icon
                color="teal"
                className="drawing-save-button"
                onClick={() => {
                  if (simulationDrawingId) {
                    saveDrawing();
                    return;
                  }
                  setIsOpenSavingModal(true);
                }}
              >
                {simulationDrawingId ? "Update" : "Save"}
              </Button>
            }
          />

          <Popup
            content="Calculate Mapping Data."
            size="mini"
            trigger={
              <Button
                icon
                color="teal"
                className="drawing-calculate-button"
                onClick={() => {
                  setCalculateModalOpen(true);
                }}
              >
                Calculate
              </Button>
            }
          />

          {!!nodeItemId ? <UpdateNodeModal /> : null}
          {isOpenSavingModal || calculateModalOpen ? (
            <SimulationDrawingSaveModal
              title={simulateDrawingToolData?.drawingName}
              note={simulateDrawingToolData?.drawingNote}
              isCalculation={calculateModalOpen}
              closeModal={() => {
                closeSaveModal();
              }}
              onSave={(title: string, note: string) => {
                onClickApprove(title, note);
              }}
            />
          ) : null}
          <DrawingErrorsModal
            errors={putDataToQueueErrors}
            setPutDataToQueueErrors={setPutDataToQueueErrors}
          />
        </Sidebar.Pushable>
      </ReactFlowProvider>
    </DrawingContext.Provider>
  );
};

export default SimulateDrawingTool;
