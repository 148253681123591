import React, { useState, memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { CommonContainer } from "../commonContainer/commonContainer";
import defaultUserImage from "../../assets/images/default_profile_pic.jpeg";
import { HeaderWebView } from "./HeaderWebView";
import { HeaderMobileView } from "./HeaderMobileView";
import { nameSort } from "../../helpers/NameSort";
import { ImageView } from "../ImageView/ImageView";
import { Icon, Dimmer, Loader } from "semantic-ui-react";
import ModalMenuBar from "../menuBar/ModalMenu";

import "./Header.scss";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useGetCompanySearch, useGetCompanyToken } from "../../api/company";
import {
  getUserIdInLocal,
  saveCompanyTenantId,
  saveCompanyTokenInLocal,
  saveRefreshTokenInLocal,
  saveTokenExpiration,
} from "../../utils/cacheStorage";
import { errorView } from "../../helpers/ErrorHandler";
import { usePostUserLogout } from "../../api/userDetails";
import {
  useGetNotification,
  useNotificationRead,
} from "../../api/notification";
import { getHeaderMenuItems } from "../../helpers/AuthorizationHelper";
import UserProfileModal from "../../screens/user/userProfile/UserProfileModal";
import { userRoles } from "../../routes/useRoleAccess";
import { images } from "../../assets/images";

export const Header = memo(({ userDetails, setVisible, isLogin }: any) => {
  const navigate: any = useNavigate();
  const [visibleModal, setVisibleModal] = useState(false);
  const { user, newNotification, dispatch }: any = useAuthContext();
  const [visibleUserProfile, setVisibleUserProfile] = useState(false);
  const [loadMore, setLoadMore] = useState(0);

  //custom hook user logout
  const { isLoading: loadLogoutUser, mutate: userLogOut } = usePostUserLogout();

  const {
    isLoading: isLoadingNotification,
    data: notificationData,
    refetch,
  } = useGetNotification(user, newNotification, loadMore);

  const { mutate: updateIsReadNotification } = useNotificationRead();

  //custom hook load company
  const {
    data: company,
    isLoading: loadCompanyData,
    mutate,
  } = useGetCompanySearch();

  //custom hook get company token
  const {
    data: useGetCompanyTokenData,
    isLoading: loadCompanyToken,
    mutate: getCompanyToken,
    isSuccess = false,
  } = useGetCompanyToken();

  useEffect(() => {
    if (localStorage.getItem("IdToken")) {
      mutate("");
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      if (useGetCompanyTokenData?.token) {
        saveCompanyTokenInLocal(useGetCompanyTokenData?.token || null);
        saveRefreshTokenInLocal(useGetCompanyTokenData?.refreshToken || null);
        saveTokenExpiration(useGetCompanyTokenData?.expiredIn || null);
        window.location.reload();
      } else {
        errorView("Company loading issue");
      }
    }
  }, [isSuccess]);

  const getSearchValue = (data: any) => {
    const tenantId = data?.result?.other?._id || data?.result?._id;
    const title = data?.result?.other?.title || data?.result?.title;
        
    if (localStorage.getItem("IdToken")) {
      saveCompanyTenantId(tenantId);
      getCompanyToken(tenantId);
      dispatch({ type: "SET_COMPANY", payload: title });
      navigate("/");
    }
  };

  const userLogout = () => {
    userLogOut("", {
      onSuccess() {
        dispatch({ type: "LOGOUT", payload: null });
        navigate("/auth");
      },
      onError() {
        errorView("User logout fail");
      },
    });
  };

  const onClickNotification = (id: string) => {
    updateIsReadNotification(id, {
      onSuccess: () => {
        refetch();
      },
    });
  };

  //handle loading
  if (loadCompanyToken || loadLogoutUser) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }
  const options = getHeaderMenuItems(navigate);
  options.push({
    key: "profile",
    text: "Profile",
    value: "profile",
    onClick: () => {
      setVisibleUserProfile(true);
    },
  });
  options.push({
    key: "logout",
    text: "Logout",
    value: "logout",
    onClick: () => {
      userLogout();
    },
  });
  const userImage = (
    <>
      {isLogin ? (
        <></>
      ) : (
        <>
          <label className="userName">
            Hi, {nameSort(user?.firstName || "")}
          </label>
          <ImageView
            avatar={true}
            imgOne={user?.profileUrl || defaultUserImage}
            imgTwo={user?.profileUrl || defaultUserImage}
            addRandomId={false}
            customImageView={"userProfileTopHeader"}
          />
          <ImageView
            avatar={true}
            imgOne={images?.DropDownIcon || defaultUserImage}
            imgTwo={images?.DropDownIcon || defaultUserImage}
            addRandomId={false}
            customImageView={"userProfileTopHeader"}
          />
        </>
      )}
    </>
  );

  return (
    <>
      <HeaderWebView
        options={options}
        userImage={userImage}
        navigate={navigate}
        userDetails={userDetails}
        company={company}
        getSearchValue={getSearchValue}
        loadCompanyData={loadCompanyData}
        loadCompanyToken={loadCompanyToken}
        searchCompany={mutate}
        isLoadingNotification={isLoadingNotification}
        notificationData={notificationData}
        updateLoadMore={(isListEmpty: boolean) => {
          setLoadMore(isListEmpty ? 0 : loadMore + 10);
        }}
        onClickNotification={onClickNotification}
      />
      <CommonContainer>
        <HeaderMobileView
          options={options}
          userImage={userImage}
          navigate={navigate}
          userDetails={userDetails}
          setVisible={setVisible}
          modalStatue={() => setVisibleModal(!visibleModal)}
          company={company}
          getSearchValue={getSearchValue}
          loadCompanyData={loadCompanyData}
          loadCompanyToken={loadCompanyToken}
          searchCompany={mutate}
        />
      </CommonContainer>
      <ModalMenuBar
        visibleModal={visibleModal}
        setVisibleModal={() => setVisibleModal(false)}
      />
      {!isLogin && (
        <UserProfileModal
          visibleModal={visibleUserProfile}
          setVisibleModal={() => {
            setVisibleUserProfile(false);
          }}
          id={getUserIdInLocal()}
          userRolesData={userRoles}
        />
      )}
    </>
  );
});
