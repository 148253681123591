import { useState } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Grid,
  Table,
} from "semantic-ui-react";
import {
  AccordionTitleView,
  CommonTable,
  CustomButton,
  DropDown,
} from "../../../components";
import "./CultivarForm.scss";
import { InputNumber } from "../../../components/InputText/InputNumber";
import { MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import { isEmpty } from "lodash";

const FuelUse = ({
  energySource,
  energySourceData,
  onEditHandler,
  control,
  setVisibleDeleteModal,
  setDeleteValue,
  setEnergySource,
  energyUnitType,
  ENERGY_UNIT_TYPE,
  errors,
  cultivationType,
  setEnergyUnitType,
  EnergySourceData,
  onEdit,
  editTableData,
  cancelEdit,
  onAddClickFuelUseDetails,
  fuelUseData,
}: any) => {
  const [accordionEnabled, setAccordionEnabled] = useState<boolean>(false);
  const [enableUpdateButton, setEnableUpdateButton] = useState<any>(false);

  const handleClick = () => {
    setAccordionEnabled(!accordionEnabled);
  };
  return cultivationType === "Supplier" ? (
    <Accordion>
      <AccordionTitle active={accordionEnabled} onClick={handleClick}>
        <AccordionTitleView
          accordionEnabled={accordionEnabled}
          title={"Fuel Use Details"}
        />
      </AccordionTitle>
      <AccordionContent active={accordionEnabled}>
        <div>
          <Grid>
            <Grid.Column computer={3} tablet={8} mobile={16}>
              <DropDown
                labelName={"Energy Source"}
                placeholder="Select Energy Source"
                customGridColumn={"customDropDownGrid"}
                defaultValue={energySource}
                currentData={energySourceData}
                handleChangeState={(e: any, { value }: any) => {
                  setEnergySource(value);
                }}
                search
                key={"energy_source"}
              />
            </Grid.Column>
            <Grid.Column computer={3} tablet={6} mobile={16}>
              <InputNumber
                transform={{
                  input: (value: any) =>
                    isNaN(value) || value === 0 ? "" : value.toString(),
                  output: (e: any) => {
                    return e;
                  },
                }}
                control={control}
                labelName={"Energy Used"}
                placeholder="Energy Used"
                name="energyUsed"
                errors={errors?.energyUsed}
              />
            </Grid.Column>
            <Grid.Column computer={3} tablet={8} mobile={16}>
              <DropDown
                labelName={"Energy Unit type"}
                placeholder="Select Energy Unit type"
                customGridColumn={"customDropDownGrid"}
                defaultValue={energyUnitType}
                currentData={ENERGY_UNIT_TYPE}
                handleChangeState={(e: any, { value }: any) => {
                  setEnergyUnitType(value);
                }}
                search
                key={"energy_unit"}
              />
            </Grid.Column>
            <Grid.Column computer={2} tablet={2} mobile={16}>
              {onEdit ? (
                <>
                  <Grid>
                    <Grid.Column computer={8}>
                      <CustomButton
                        theme="green"
                        title="edit"
                        customColumnStyle={"plusButton"}
                        onClick={() => editTableData("FuelUsed")}
                      />
                    </Grid.Column>

                    <Grid.Column computer={8}>
                      <CustomButton
                        theme="green"
                        title="cancel"
                        customColumnStyle={"plusButton"}
                        onClick={() => cancelEdit("FuelUsed")}
                      />
                    </Grid.Column>
                  </Grid>
                </>
              ) : (
                <CustomButton
                  theme="green"
                  title={enableUpdateButton ? "Update" : ""}
                  icon={!enableUpdateButton ? "plus" : null}
                  disabled={
                    isEmpty(energySource.toString()) ||
                    isEmpty(energyUnitType.toString())
                  }
                  customColumnStyle={"plusButton"}
                  onClick={() => {
                    onAddClickFuelUseDetails();
                    setEnableUpdateButton(false);
                  }}
                />
              )}
            </Grid.Column>
          </Grid>

          {fuelUseData?.length > 0 && (
            <Grid.Column
              computer={16}
              tablet={16}
              mobile={16}
              className="pesticideTable"
            >
              <CommonTable
                tableHeaderData={EnergySourceData}
                className="pesticideTable"
              >
                {fuelUseData?.map((item: any, index: any) => {
                  return (
                    <Table.Row key={`pesticide_${item?.id}`}>
                      <Table.Cell>
                        <p>
                          {energySourceData?.find(
                            (e: any) => e?.value == item?.energySource
                          )?.text ?? ""}
                        </p>
                      </Table.Cell>
                      <Table.Cell>
                        <p>{item?.energyUsed ?? ""}</p>
                      </Table.Cell>
                      <Table.Cell>
                        <p>
                          {ENERGY_UNIT_TYPE?.find(
                            (e: any) => e?.key == item?.energyUnit
                          )?.text ?? ""}
                        </p>
                      </Table.Cell>
                      <Table.Cell>
                        <Grid>
                          <Grid.Column computer={8} tablet={16} mobile={16}>
                            <MdModeEditOutline
                              cursor={"pointer"}
                              size={24}
                              color="var(--tableEditIcon)"
                              onClick={() => {
                                onEditHandler(item, "FuelUsed", index);
                                setEnableUpdateButton(true);
                              }}
                            />
                          </Grid.Column>
                          <Grid.Column computer={8} tablet={16} mobile={16}>
                            <MdDeleteOutline
                              cursor={"pointer"}
                              size={24}
                              color="var(--tableEditIcon)"
                              onClick={() => {
                                setVisibleDeleteModal(true);
                                setDeleteValue({
                                  type: "FuelUsed",
                                  item: item ?? "",
                                  index: index,
                                });
                              }}
                            />
                          </Grid.Column>
                        </Grid>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </CommonTable>
            </Grid.Column>
          )}
        </div>
      </AccordionContent>
    </Accordion>
  ) : null;
};

export default FuelUse;
