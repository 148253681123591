import { Grid } from "semantic-ui-react";
import { TitleView } from "../titleView/TitleView";
import { InputNumber } from "../InputText/InputNumber";
import { CustomButton } from "../button/Button";

const MeasurementDetails = ({
  control,
  errors,
  customCapacityValidation,
  customInputValidation,
  customOutputValidation,
  addIngredientActive,
  setViewIngredient,
}: any) => {
  return (
    <>
      <TitleView title="Measurement Details" />
      <Grid>
        <Grid.Column computer={4} tablet={8} mobile={16}>
          <InputNumber
            ref={customCapacityValidation}
            transform={{
              input: (value: any) =>
                isNaN(value) || value === 0 ? "" : value.toString(),
              output: (e: any) => {
                return e;
              },
            }}
            control={control}
            labelName={"Capacity (kg input/hr)*"}
            placeholder="Capacity (kg input/hr)"
            name={`capacity`}
            errors={errors?.capacity}
            errorMessage="Capacity is required"
            customRequired={true}
          />
        </Grid.Column>
        <Grid.Column computer={4} tablet={8} mobile={16}>
          <InputNumber
            ref={customInputValidation}
            transform={{
              input: (value: any) =>
                isNaN(value) || value === 0 ? "" : value.toString(),
              output: (e: any) => {
                return e;
              },
            }}
            control={control}
            labelName={"Input(kg)*"}
            placeholder="Input(kg)"
            name={`input`}
            errors={errors?.input}
            errorMessage="Input is required"
            customRequired={true}
          />
        </Grid.Column>
        <Grid.Column computer={4} tablet={8} mobile={16}>
          <InputNumber
            ref={customOutputValidation}
            transform={{
              input: (value: any) =>
                isNaN(value) || value === 0 ? "" : value.toString(),
              output: (e: any) => {
                return e;
              },
            }}
            control={control}
            labelName={"Output(kg)*"}
            placeholder="Output(kg)"
            name={`output`}
            errors={errors?.output}
            errorMessage="Output is required"
            customRequired={true}
          />
        </Grid.Column>
        <Grid.Column computer={4} tablet={8} mobile={16}>
          <InputNumber
            transform={{
              input: (value: any) =>
                isNaN(value) || value === 0 ? "" : value.toString(),
              output: (e: any) => {
                return e;
              },
            }}
            control={control}
            labelName={"BioWaste (kg waste/kg input)"}
            placeholder="BioWaste (kg waste/kg input)"
            name={`sideStream`}
          />
        </Grid.Column>

        <Grid.Column
          computer={4}
          tablet={8}
          mobile={16}
          className="paddingRemoveTop"
        >
          <InputNumber
            transform={{
              input: (value: any) =>
                isNaN(value) || value === 0 ? "" : value.toString(),
              output: (e: any) => {
                return e;
              },
            }}
            control={control}
            labelName={"Heat (MJ/kg input)"}
            placeholder="0"
            name={`heat`}
          />
        </Grid.Column>

        <Grid.Column
          computer={4}
          tablet={8}
          mobile={16}
          className="paddingRemoveTop"
        >
          <InputNumber
            transform={{
              input: (value: any) =>
                isNaN(value) || value === 0 ? "" : value.toString(),
              output: (e: any) => {
                return e;
              },
            }}
            control={control}
            labelName={"Electricity (kWh/kg input)"}
            placeholder="0"
            name={`electricity`}
            valueAsNumber={true}
          />
        </Grid.Column>
        <Grid.Column
          computer={4}
          tablet={8}
          mobile={16}
          className="paddingRemoveTop"
        >
          <InputNumber
            transform={{
              input: (value: any) =>
                isNaN(value) || value === 0 ? "" : value.toString(),
              output: (e: any) => {
                return e;
              },
            }}
            control={control}
            labelName={"Water (m3 per process)"}
            placeholder="0"
            name={`water`}
            valueAsNumber={true}
          />
        </Grid.Column>
        <Grid.Column
          computer={4}
          tablet={8}
          mobile={16}
          className="paddingRemoveTop"
        ></Grid.Column>
        {addIngredientActive && (
          <Grid.Column computer={8} tablet={16} mobile={16}>
            <label className={`inputTextLable`}>Associate ingredients</label>
            <CustomButton
              customColumnStyle="processingCustomColumnStyle"
              customButtonStyle="customBtn"
              theme="green"
              title={`+ ${"  "}Add Ingredients`}
              onClick={() => setViewIngredient(true)}
            />
          </Grid.Column>
        )}
      </Grid>
    </>
  );
};

export default MeasurementDetails;
