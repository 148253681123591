import { useState, useEffect } from "react";
import { Grid, Table } from "semantic-ui-react";
import { CommonModal, CommonTable } from "../../../components";
import {
  ProductSummaryTableCol,
  ProductSummaryHistoryTableCol,
} from "../../../config/constants";
import { useGetProductSummaryHistory } from "../../../api/productSummary";
import PaginationView from "../../../components/pagination/Pagination";
import { LoadingPlaceholder } from "../../../components/LoadingPlaceholder/LoadingPlaceholder";

const ProductSummaryDetailHistory = ({
  visibleModal,
  setVisibleModal,
  productId,
  salesUnitId,
}: any) => {
  const [totalImpact, setTotalImpact] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(10);
  const [totalSummaryHistory, setTotalSummaryHistory] = useState(0);
  const {
    data: productSummaryHistory,
    isFetching: isProductSummaryHistoryLoading,
    refetch,
  } = useGetProductSummaryHistory({
    pageNumber,
    pageSize,
    productId,
    salesUnitId,
  });

  const productSummaryHistoryData =
    productSummaryHistory?.result?.productSummaryHistory || [];

  useEffect(() => {
    if (productSummaryHistory) {
      setPageNumber(productSummaryHistory.page);
      setPageSize(productSummaryHistory.pageSize);
      setTotalSummaryHistory(productSummaryHistory.total);
    }
  }, [productSummaryHistory]);

  useEffect(() => {
    setTotalPages(Math.ceil(totalSummaryHistory / pageSize));
  }, [totalSummaryHistory, pageSize]);

  useEffect(() => {
    refetch();
  }, [pageNumber, pageSize]);

  const loadHistoryTableData = () => {
    return productSummaryHistoryData.map((data: any, index: any) => {
      const { climateChange, totalImpact } = data;
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>
              {new Date(climateChange?.createdDate).toDateString()}{" "}
              {new Date(climateChange?.createdDate).toLocaleTimeString()}
            </p>
          </Table.Cell>
          <Table.Cell>
            <p>{`${climateChange.measure?.toFixed(2)} ${
              climateChange.unit
            }`}</p>
          </Table.Cell>
          <Table.Cell>
            <Grid>
              <Grid.Column
                computer={16}
                tablet={16}
                mobile={16}
                key={index}
                className="alignRight addViewDetails"
              >
                <div
                  key={index}
                  className={"customButtonViewSelect customButtonViewTitle"}
                  onClick={() => setTotalImpact(totalImpact)}
                >
                  <p className={"customButtonTextSelect"}>Details</p>
                </div>
              </Grid.Column>
            </Grid>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  const loadTableData = () => {
    return totalImpact.map((data: any, index: any) => {
      return (
        <Table.Row className="tbleR" key={index}>
          <Table.Cell>
            <p>{data.indicator}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data.measure}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{data.unit}</p>
          </Table.Cell>
          <Table.Cell>
            <Grid>
              <Grid.Column
                computer={16}
                tablet={16}
                mobile={16}
                key={index}
                className="alignRight addViewDetails"
              ></Grid.Column>
            </Grid>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <>
      <CommonModal
        modalOpenState={visibleModal}
        modalCloseState={() => {
          setVisibleModal(false);
          setTotalImpact([]);
        }}
        centered={true}
        title="Product Summary History"
        style={{ overflow: "scroll" }}
      >
        <CommonTable tableHeaderData={ProductSummaryHistoryTableCol}>
          {isProductSummaryHistoryLoading ? (
            <Table.Row>
              <Table.Cell colSpan="7">
                <LoadingPlaceholder />
              </Table.Cell>
            </Table.Row>
          ) : (
            loadHistoryTableData()
          )}
        </CommonTable>
        <PaginationView
          currentPageNo={pageNumber}
          totalPages={totalPages}
          pageSize={pageSize}
          currentSelectPage={(page: number) => {
            setPageNumber(page);
          }}
          changePageSize={(pageSize: number) => {
            setPageNumber(1);
            setPageSize(pageSize);
          }}
        />
        {totalImpact.length > 0 ? (
          <CommonTable tableHeaderData={ProductSummaryTableCol}>
            {loadTableData()}
          </CommonTable>
        ) : null}
      </CommonModal>
    </>
  );
};

export default ProductSummaryDetailHistory;
