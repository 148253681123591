import { useState } from "react";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import "./pagination.scss";
import { Grid, Dropdown, Pagination, Icon } from "semantic-ui-react";
import _ from "lodash";

const pageSizes = [
  {
    key: 5,
    text: `5`,
    value: 5,
  },
  {
    key: 10,
    text: `10`,
    value: 10,
  },
  {
    key: 20,
    text: `20`,
    value: 20,
  },
  {
    key: 50,
    text: `50`,
    value: 50,
  },
];

const PaginationView = ({
  totalPages = 0, // when no of page is 0 hide the pagination
  currentPageNo = 1,
  pageSize = 10,
  currentSelectPage = () => {},
  changePageSize = () => {},
  customMainPagination,
  showFirstAndLastNav = true,
  showPreviousAndNextNav = true,
}: any) => {
  const [activePage, setActivePage] = useState(currentPageNo);
  const boundaryRange = 1;
  const siblingRange = 1;
  const showEllipsis = 1;

  const handlePaginationChange = (e: any, { activePage }: any) => {
    setActivePage(activePage);
    currentSelectPage(activePage);
  };

  const handlePreviousAndNext = (status: any) => {
    const index = pageSizes.findIndex((a) => a.value == pageSize);
    if (index === -1) {
      changePageSize(10);
    }
    if (status == "next") {
      const next = pageSizes[index + 1];
      if (!next) {
        changePageSize(10);
      } else {
        changePageSize(next.value);
      }
    } else {
      const pre = pageSizes[index - 1];
      if (!pre) {
        changePageSize(10);
      } else {
        changePageSize(pre.value);
      }
    }
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column floated="left" computer={10}>
          <div className={`paginationMain ${customMainPagination}`}>
            <Pagination
              activePage={activePage}
              boundaryRange={boundaryRange}
              onPageChange={handlePaginationChange}
              size="mini"
              siblingRange={siblingRange}
              totalPages={totalPages}
              ellipsisItem={showEllipsis ? undefined : null}
              firstItem={showFirstAndLastNav ? undefined : null}
              lastItem={showFirstAndLastNav ? undefined : null}
              prevItem={showPreviousAndNextNav ? undefined : null}
              nextItem={showPreviousAndNextNav ? undefined : null}
            />
          </div>
        </Grid.Column>
        <Grid.Column floated="right" computer={6} className="paginate_div">
          <Grid>
            <Grid.Column computer={4}>
              <Dropdown
                compact
                value={pageSize}
                selection
                options={pageSizes}
                onChange={(e, { value }) => {
                  changePageSize(value);
                }}
              />
            </Grid.Column>
            <Grid.Column computer={7} className="pageDetailsView">
              <p className="paginationContent">Showing 0 to {pageSize} to many</p>
            </Grid.Column>
            <Grid.Column computer={4} className="nextPreviousMainView">
              <Grid>
                <Grid.Column
                  computer={8}
                  onClick={() => handlePreviousAndNext("previous")}
                >
                  <Icon className="nextPreviousIcon" size="large" name="angle left" />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  onClick={() => handlePreviousAndNext("next")}
                >
                  <Icon className="nextPreviousIcon" size="large" name="angle right" />
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default PaginationView;
