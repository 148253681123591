import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Grid,
  Table,
  Accordion,
  AccordionContent,
  AccordionTitle,
} from "semantic-ui-react";
import { useForm } from "react-hook-form";

import {
  useDeleteSimulationWasteManagementData,
  usePostSimulationWasteManagementData,
  usePutSimulationWasteManagementData,
  usePostPGSimulationWasteManagementData,
  usePutPGSimulationWasteManagementData,
  useDeletePGSimulationWasteManagementData,
} from "../../api/simulation/wasteManagement";
import { errorView, successMessage } from "../../helpers/ErrorHandler";
import ConfirmModal from "../confirmViewModal/ConfirmModal";
import { DropDown } from "../selectDropDown/DropDown";
import { InputNumber } from "../InputText/InputNumber";
import { CustomButton } from "../button/Button";
import { CommonTable } from "../commonTable/CommonTable";
import { LoadWasteManagementTableData } from "./WasteManagementTableView";
import { WasteManagementDataCol } from "../../config/constants";
import { InputText } from "../InputText/InputText";
import useWindowDimensions from "../../hooks/windowsSize";
import "./WasteManagement.scss";
import { isArray } from "lodash";
import { AccordionTitleView } from "../accordionTitleView/AccordionTitleView";
import { AccordionNames } from "../../helpers/AccordionHelper";

export const SimulationWasteManagementContent = ({
  wasteManagementType,
  wasteManagement,
  processId,
  sideStreamValue,
  refetchWasteData,
  type,
  supplierDataHandle = false,
  handleOpenValidation = () => {},
  originalProcessData,
  currentAccordionStatus,
}: any) => {
  const { height } = useWindowDimensions();
  const [defaultManagementType, setDefaultManagementType] = useState<any>(1);
  const [enableEdit, setEnableEdit] = useState<any>(false);
  const [updateData, setUpdateData] = useState<any>({});
  const [deleteData, setDeleteData] = useState<any>({});
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [accordionEnabled, setAccordionEnabled] = useState<boolean>(false);

  const { mutate: postWasteDataSet } = usePostSimulationWasteManagementData();
  const { mutate: putWasteDataSet } = usePutSimulationWasteManagementData();
  const { mutate: deleteWasteDataSet } =
    useDeleteSimulationWasteManagementData();

  const { mutate: postPGWasteDataSet } =
    usePostPGSimulationWasteManagementData();
  const { mutate: putPGWasteDataSet } = usePutPGSimulationWasteManagementData();
  const { mutate: deletePGWasteDataSet } =
    useDeletePGSimulationWasteManagementData();

  const originalWasteManagementData =
    originalProcessData?.wasteManagements || [];

  const values = useMemo(() => {
    return {
      managementName: null,
      sideStreamsDistribution: 0,
      totalSide: 0,
      sideStream: sideStreamValue,
    };
  }, []);

  useEffect(() => {
    setTotalValue();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    mode: "all",
    defaultValues: values,
    values: values,
  });

  //check waste data insert validation
  const checkWasteDataValidation = (data: any) => {
    if (data.sideStreamsDistribution == 0) {
      errorView("Side Streams Distribution must greater than 0");
      return false;
    } else {
      return true;
    }
  };

  //reset after change all data
  const resetData = (Status?: String) => {
    setDefaultManagementType(1);
    refetchWasteData();
    setEnableEdit(false);
    setValue("managementName", null);
    setValue("sideStreamsDistribution", 0);
  };

  const handleWasteDataSet = (
    data: any,
    isEditMode: boolean,
    type: string,
    successMessageText: string,
    resetAction: string
  ) => {
    const apiMethod =
      type === "process"
        ? isEditMode
          ? resetAction === "Delete"
            ? deletePGWasteDataSet
            : putPGWasteDataSet
          : postPGWasteDataSet
        : isEditMode
        ? resetAction === "Delete"
          ? deleteWasteDataSet
          : putWasteDataSet
        : postWasteDataSet;

    apiMethod(data, {
      onSuccess() {
        successMessage(successMessageText);
        resetData(resetAction);
      },
    });
  };

  //create new waste
  const createNewWaste = (data: any) => {
    if (checkWasteDataValidation(data)) {
      const wasteData = {
        managementName: data.managementName,
        managementTypeId: defaultManagementType,
        sideStreamsDistribution: data.sideStreamsDistribution,
      };

      if (enableEdit) {
        const createWaste = {
          wasteManagementId: updateData._id,
          type,
          supplierDataHandle,
          wasteData: {
            ...updateData,
            ...wasteData,
          },
        };

        handleWasteDataSet(
          createWaste,
          true,
          type,
          "Waste Management edited successfully",
          "Edit"
        );
      } else {
        const createChemicals = {
          processingId: processId,
          type,
          supplierDataHandle,
          wasteData,
        };

        handleWasteDataSet(
          createChemicals,
          false,
          type,
          "Waste Management created successfully",
          "Create"
        );
      }
    }
  };

  //delete waste data
  const deleteWasteData = (data: any) => {
    let deleteWasteData = {
      type,
      supplierDataHandle,
      wasteData: {
        wasteManagementId: data._id,
        processingId: processId,
      },
    };

    handleWasteDataSet(
      deleteWasteData,
      true,
      type,
      "Waste Management deleted successfully",
      "Delete"
    );
  };

  //handle delete cancel
  const handleDeleteCancel = () => {
    setVisibleDeleteModal(false);
    setDeleteData({});
  };

  //handle delete
  const handleDeleteApprove = () => {
    if (visibleDeleteModal) {
      setVisibleDeleteModal(false);
      deleteWasteData(deleteData);
    }
  };

  //update waste data
  const updateWasteData = (data: any) => {
    setUpdateData(data);
    setDefaultManagementType(data?.managementTypeId || 1);
    setValue("managementName", data?.managementName || "");
    setValue("sideStreamsDistribution", data?.sideStreamsDistribution || 0);
    setEnableEdit(true);
  };

  //delete data collect
  const deleteDataAction = (data: any) => {
    setDeleteData(data);
    setVisibleDeleteModal(true);
  };

  //get management type data
  const getManagementType = useCallback(() => {
    if (isArray(wasteManagementType)) {
      let findManagementType = wasteManagementType?.find(
        (e: any) => e.value === defaultManagementType.toString()
      );
      return findManagementType?.value || "";
    }
  }, [wasteManagementType, defaultManagementType]);

  const getSideStreamTotalValue = (data: any) => {
    if (isArray(data)) {
      let totalSideStream = data.reduce(
        (a, b) => a + b.sideStreamsDistribution,
        0
      );
      return totalSideStream;
    }
  };

  //get total stream
  const getSideStreamTotal = useMemo(() => {
    let totalValue = getSideStreamTotalValue(wasteManagement);

    setValue("totalSide", totalValue);
    return totalValue;
  }, [wasteManagement]);

  // //get total stream
  const getOriginalSideStreamTotal = useMemo(() => {
    return getSideStreamTotalValue(originalWasteManagementData);
  }, [originalWasteManagementData]);

  //load total stream
  const setTotalValue = useCallback(() => {
    setValue("totalSide", getSideStreamTotal);
  }, [getSideStreamTotal]);

  const handleClick = () => {
    if (!handleOpenValidation()) return;
    const status = currentAccordionStatus(
      !accordionEnabled,
      AccordionNames.WASTE_MANAGEMENT
    );
    setAccordionEnabled(status);
  };

  return (
    <>
      <Accordion>
        <AccordionTitle active={accordionEnabled} onClick={handleClick}>
          <AccordionTitleView
            accordionEnabled={accordionEnabled}
            title={"Waste/Side Stream"}
          />
        </AccordionTitle>
        <AccordionContent active={accordionEnabled}>
          <div>
            <Grid>
              <Grid.Row divided>
                <Grid.Column computer={8} tablet={8} mobile={8}>
                  <Grid>
                    <Grid.Column computer={5} tablet={16} mobile={16}>
                      <label className="textLabel">
                        Total Waste/Side Stream (kg)
                      </label>
                      <br />
                      <label className="textLabel">
                        {getOriginalSideStreamTotal}
                      </label>
                    </Grid.Column>
                    <Grid.Column
                      style={{ marginBottom: 60 }}
                      computer={5}
                      tablet={16}
                      mobile={16}
                    >
                      <label className="textLabel">
                        Waste/Side Stream (kg)
                      </label>
                      <br />
                      <label className="textLabel">{sideStreamValue}</label>
                    </Grid.Column>
                    {originalWasteManagementData?.length > 0 && (
                      <Grid.Column
                        computer={16}
                        tablet={16}
                        mobile={16}
                        className="pesticideTable"
                      >
                        <CommonTable
                          tableHeaderData={WasteManagementDataCol.filter(
                            (e: any) => e.name !== ""
                          )}
                          className="pesticideTable"
                          isSimulateView
                        >
                          {originalWasteManagementData?.map(
                            (data: any, index: any) => {
                              return (
                                <Table.Row className="tbleR" key={index}>
                                  <Table.Cell>
                                    <p>{data?.managementName}</p>
                                  </Table.Cell>
                                  <Table.Cell>
                                    <p>
                                      {wasteManagementType?.find(
                                        (e: any) =>
                                          e.value ===
                                          data?.managementTypeId?.toString()
                                      )?.text || ""}
                                    </p>
                                  </Table.Cell>
                                  <Table.Cell>
                                    <p>{data?.sideStreamsDistribution}</p>
                                  </Table.Cell>
                                </Table.Row>
                              );
                            }
                          )}
                        </CommonTable>
                      </Grid.Column>
                    )}
                  </Grid>
                </Grid.Column>
                <Grid.Column computer={8} tablet={8} mobile={8}>
                  <Grid className="wasteManagementDetailsInsertMain">
                    <Grid.Column computer={5}>
                      <InputText
                        labelName={" Total Waste/Side Stream (kg)"}
                        errors={errors.totalSide}
                        register={register}
                        placeholder=" Total Waste/Side Stream (kg)"
                        name={"totalSide"}
                        defaultValues={getSideStreamTotal}
                      />
                    </Grid.Column>
                    <Grid.Column computer={5}>
                      <InputText
                        labelName={"Waste/Side Stream (kg)"}
                        errors={errors.sideStream}
                        register={register}
                        placeholder="Waste/Side Stream (kg)"
                        name={"sideStream"}
                        defaultValues={sideStreamValue}
                        disabled
                      />
                    </Grid.Column>
                    <Grid.Column computer={16}>
                      <div className="wasteManagementDetailsInsertBox">
                        <form onSubmit={handleSubmit(createNewWaste)}>
                          <Grid>
                            <Grid.Column computer={6}>
                              <InputText
                                labelName={"Management name"}
                                errors={errors.managementName}
                                register={register}
                                placeholder="Management name"
                                name={"managementName"}
                                required={true}
                                errorMessage="Management name is required"
                              />
                            </Grid.Column>
                            <Grid.Column computer={6}>
                              <DropDown
                                required={true}
                                labelName={"Management type"}
                                placeholder="Management type"
                                currentData={wasteManagementType}
                                defaultValue={getManagementType()}
                                customGridColumn={"customGridColomnType"}
                                handleChangeState={(e: any, { value }: any) => {
                                  setDefaultManagementType(value);
                                }}
                              />
                            </Grid.Column>
                            <Grid.Column computer={6}>
                              <InputNumber
                                control={control}
                                errors={errors.sideStreamsDistribution}
                                labelName={"Distribution (kg)"}
                                placeholder="Distribution (kg)"
                                name="sideStreamsDistribution"
                                required={true}
                                errorMessage="Distribution is required"
                              />
                            </Grid.Column>
                            <Grid.Column
                              computer={2}
                              tablet={16}
                              mobile={16}
                              className="wasteManagementAdd"
                            >
                              <CustomButton
                                onClick={handleSubmit(createNewWaste)}
                                title={enableEdit ? "Edit" : "Add"}
                                theme="green"
                                type="submit"
                              />
                            </Grid.Column>
                          </Grid>
                        </form>
                      </div>
                    </Grid.Column>
                  </Grid>
                  <Grid style={{ marginTop: "20px" }}>
                    <Grid.Column computer={16} tablet={16} mobile={16}>
                      <CommonTable tableHeaderData={WasteManagementDataCol}>
                        <LoadWasteManagementTableData
                          wasteManagement={wasteManagement}
                          wasteManagementType={wasteManagementType}
                          updateWasteData={updateWasteData}
                          deleteDataAction={deleteDataAction}
                        />
                      </CommonTable>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </AccordionContent>
      </Accordion>
      <ConfirmModal
        viewModal={visibleDeleteModal}
        closeModal={() => setVisibleDeleteModal(false)}
        cancel={() => {
          handleDeleteCancel();
        }}
        approve={() => {
          handleDeleteApprove();
        }}
        title="Delete Chemical Data"
        subTitle="Are you sure you want to delete chemical data?"
      />
    </>
  );
};
